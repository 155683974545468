<template>
  <div>
    <b-row>
      <b-button
        class="primary ml-1 mb-2"
        icon-pack="feather"
        icon="icon-arrow-left"
        variant="primary"
        @click="goBack"
      >Go Back</b-button>
    </b-row>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card>
        <b-col>
          <div class="p-8">
            <div class="b-card__title my-2">
              <h4 class="text-center">
                {{ getFrom === 'job'? 'Job Applications' : 'Job Applications' }}
              </h4>
              <p
                v-if="events_applicants_data.length === 0"
                class="text-center mt-6"
              >
                There are no applicants for this  {{ getFrom === 'job'? 'job' : 'event' }}
              </p>
            </div>
          </div>
        </b-col>

        <div class="d-flex flex-wrap justify-content-between w-100">
          <div
            class="mx-6"
            :class="{'w-100': isMobile}"
          >
            <!--  -->
          </div>
          <div
            :class="{'w-100': isMobile}"
          >
            <b-button
              variant="primary"
              class="mt-1 mt-md-0 mb-1 shadow-lg"
              :class="{'w-100': isMobile}"
              @click="exportToCSV"
            >Export as CSV</b-button>

            <b-button
              class="mt-1 mt-md-0 mb-1 ml-1 shadow-lg"
              :class="{'w-100': isMobile}"
              variant="warning"
              @click="openQueue = true"
            >Manage Queue</b-button>
          </div>
        </div>

        <b-modal
          v-model="openQueue"
          centered
          hide-footer
          size="lg"
          title="Queue"
        >
          <b-row class="w-100 mx-auto my-2">
            <h6>Total Waiting Time: 15 minutes</h6>
          </b-row>

          <b-row class="w-100 mx-auto my-2">
            <b-card
              class="w-100"
              no-body
            >
              <b-table
                striped
                responsive
                :items="userData"
                class="mb-0 w-100"
              >
                <template #cell(module)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-card>
          </b-row>
        </b-modal>

        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :rows="events_applicants_data"
          :columns="columnDefs"

          style-class="vgt-table condensed bordered"
          compact-mode
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >

          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'created_on'"
              class="mt-2"
            >
              <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Added On"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>

            <!-- <span
              v-else-if="props.column.field === 'interview_date_time'"
              class="mt-2"
            >
              <flat-pickr
                v-model="interview_date_time_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Event Period"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span> -->
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'attendance'">
              <CellRendererAttendance :params="{...props.row}" />
            </span>

            <span v-else-if="props.column.field === 'interview_date_time'">
              <CellRendererInterview
                :params="{ ...props.row }"
              />
            </span>

            <span v-else-if="props.column.field === 'profile_incomplete'">
              <CellRendererProfile
                :params="{ ...props.row }"
              />
            </span>

            <span v-else-if="props.column.field === 'viewed'">
              <CellRendererViewed :params="{...props.row}" />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BBadge,
  BOverlay,
  BPagination,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import CellRendererProfile from './cell-renderer/CellRendererProfile.vue'
import CellRendererApplicationStatus from './cell-renderer/CellRendererApplicationStatus.vue'
import CellRendererApplicationNote from './cell-renderer/CellRendererApplicationNote.vue'
import CellRendererInterview from './cell-renderer/CellRendererInterview.vue'
import CellRendererAttendance from './cell-renderer/CellRendererAttendance.vue'
import CellRendererViewed from './cell-renderer/CellRendererViewed.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BOverlay,
    BPagination,
    VueGoodTable,
    flatPickr,
    CellRendererApplicationStatus,
    CellRendererApplicationNote,
    CellRendererProfile,
    CellRendererInterview,
    CellRendererAttendance,
    CellRendererViewed,
  },
  mixins: [
    onResizeMixins,
  ],
  data() {
    return {
      getFrom: 'event',

      openQueue: false,
      userData: [
        {
          serial_number: 1,
          name: 'John Doe',
          estimated_waiting_time: '15 minutes',
        },
      ],
      attendance_popup: false,

      event_details: {},
      events_applicants_data: [],
      isLoading: true,

      // vue-good-table
      columnDefs: [
        {
          label: 'Full Name',
          field: 'fullname',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // cellRendererFramework: 'CellRendererLink',
        },
        {
          label: 'Position',
          field: 'position',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Hiring Company',
          field: 'company.company_name',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Attendance',
          field: 'attendance',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererAttendance',
        },
        {
          label: 'Status',
          field: 'application_status',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererApplicationStatus',
        },
        {
          label: 'Note',
          field: 'note',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererApplicationNote',
        },
        {
          label: 'Interview Date',
          field: 'interview_date_time',
          filter: false,
          sortable: false,
          // type: 'date',
          // dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          // dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          // tdClass: 'text-left',
          // thClass: 'text-left',
          // filterOptions: {
          //   customFilter: true,
          //   trigger: 'enter',
          //   enabled: true,
          //   placeholder: '',
          // },
          // valueFormatter: params => this.$formatDate(params.value),
          cellRendererFramework: 'CellRendererInterview',
        },
        {
          label: 'Applicant Profile',
          field: 'profile_incomplete',
          filter: false,
          sortable: false,
          // filterOptions: {
          //   trigger: 'enter',
          //   enabled: true,
          //   placeholder: '',
          //   filterDropdownItems: [
          //     { value: true, text: 'Complete' },
          //     { value: false, text: 'Incomplete' },
          //   ],
          // },
          cellRendererFramework: 'CellRendererProfile',
        },
        {
          label: 'Profile Viewed',
          field: 'viewed',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
            filterDropdownItems: [
              { value: true, text: 'Viewed' },
              { value: false, text: 'Not Viewed' },
            ],
          },
        },
        // {
        //   label: 'Added On',
        //   field: 'created_on',
        //   type: 'date',
        //   dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
        //   dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
        //   tdClass: 'text-left',
        //   thClass: 'text-left',
        //   filterOptions: {
        //     customFilter: true,
        //     trigger: 'enter',
        //     enabled: true,
        //     placeholder: '',
        //   },
        //   valueFormatter: params => this.$formatDate(params.value),
        // },
      ],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      selectedRows: null,
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      configDateTimePicker: {
        altInput: true,
        // noCalendar: true,
        enableTime: true,
      },
      created_on_filter: null,
      interview_date_time_filter: null,

      exportingCSV: false,
    }
  },
  computed: {
  },
  created() {
    let { event_id } = this.$route.params

    if (event_id.includes('_j')) {
      this.getFrom = 'job'

      event_id = event_id.slice(0, -2)
    }

    this.getApplications(event_id)
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      const { event_id } = this.$route.params
      this.getApplications(event_id)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },

    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    goBack() {
      this.$router.go(-1)
    },

    filterColumns() {
      let updatedColumns = this.columnDefs
      if (this.event_details.event_type !== 'physical') {
        updatedColumns = updatedColumns.filter(
          column => column.field !== 'attendance',
        )
      }

      if (this.event_details.event_category !== 'group') {
        updatedColumns = updatedColumns.filter(
          column => column.field !== 'employer',
        )
      }
      if (this.event_details.event_category === 'group') {
        updatedColumns = updatedColumns.filter(
          column => column.field !== 'interview_date',
        )
      }

      this.columnDefs = updatedColumns
    },

    getApplications(event_id) {
      this.isLoading = true

      if (this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
        this.serverParams.extractCSV = true

        if (!this.$isEmpty(this.selectedRows)) {
          const selectedRowsIdArray = []
          this.selectedRows.forEach(row => {
            selectedRowsIdArray.push(row.booking_id)
          })

          this.serverParams.selectedRows = selectedRowsIdArray
        }
      }

      const query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/event-applications/${event_id}/${this.getFrom}${query}`, {
          onDownloadProgress(progressEvent) {
            const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
            }
          },
        })
        .then(response => {
          if (this.exportingCSV) {
            const FILE = window.URL.createObjectURL(new Blob([response.data]))

            const docUrl = document.createElement('a')
            docUrl.href = FILE
            docUrl.setAttribute('download', response.headers.filename)
            document.body.appendChild(docUrl)
            docUrl.click()

            this.exportingCSV = false
          } else if (response.data.success) {
            this.events_applicants_data = response.data.applications
            this.event_details = response.data.event_data
            this.totalRecords = response.data.total_records
            // this.filterColumns()

            // if (this.event_details.event_type === 'physical') {
            //   this.columnDefs.splice(3, 0, {
            //     headerName: 'Attendance',
            //     field: 'attendance',
            //     filter: true,
            //     editable: false,
            //     width: 200,
            //     cellRendererFramework: 'CellRendererAttendance',
            //     cellClass: 'font-medium truncate text-center',
            //   })
            // }
          } else {
            this.events_applicants_data = []
            this.event_details = {}
          }
          this.isLoading = false

          //! Sugianto: not working yet
          this.$toast.dismiss('exportToCSVId')
          this.$toast.update('exportToCSVId', { content: 'Finished!', options: { timeout: 5000 } })
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    exportToCSV() {
      // retrieve data from db
      this.exportingCSV = true

      this.$toast(
        {
          component: this.$toastContent,
          props: {
            id: 'exportToCSVId',
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 9999999999 },
      )

      let { event_id } = this.$route.params

      if (event_id.includes('_j')) {
        this.getFrom = 'job'

        event_id = event_id.slice(0, -2)
      }

      this.getApplications(event_id)
    },
  },
}
</script>
<style>
.ag-row .ag-cell {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}
</style>
